var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "clearflex"
  }, [_c("el-button", {
    staticClass: "fl-right",
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.relation
    }
  }, [_vm._v(_vm._s(_vm.$t("common.save")))])], 1), _vm._v(" "), _c("el-tree", {
    ref: "menuTree",
    attrs: {
      data: _vm.menuTreeData,
      "default-checked-keys": _vm.menuTreeIds,
      props: _vm.menuDefaultProps,
      "default-expand-all": "",
      "highlight-current": "",
      "node-key": "ID",
      "show-checkbox": ""
    },
    on: {
      check: _vm.nodeChange
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };